import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";

import "./App.css";

import Firebase from "./firebasehelper";

import bgEcosystem from "./assets/bg_ecosystem.png";
import yellowheart from "./assets/yellowheart.png";
import logo from "./assets/logo.png";
import ecosystem from "./assets/ecosystem.png";
import liveYourBestLife from "./assets/powerbyeco.png";
import liveYourBestLifeL from "./assets/live_your_best_life_l.png";
import googlePlay from "./assets/google_play.png";
import appStore from "./assets/app_store.png";
import ecosystemContent from "./assets/ecosystem_content.png";
import communityMember from "./assets/community_member.png";
import community from "./assets/community.png";
import communityInsider from "./assets/community_insider.png";
import communityTokens from "./assets/community_tokens.png";
import communitySolidarity from "./assets/community_solidarity.png";
import retailers from "./assets/retailers.png";
import ecoTokens from "./assets/ecopay_ecotokens.png";
import ecoPayTokens from "./assets/eco_pay_tokens.png";
import ecoPayTokensMobile from "./assets/eco_pay_tokens_mobile.png";
import bgEcoTokens from "./assets/bg_ecotokens.png";
import ecoPost from "./assets/eco_post.png";
import ecoAnswer from "./assets/eco_answer.png";
import ecoHelp from "./assets/eco_help.png";
import ecoRefer from "./assets/eco_refer.png";
import ecoBuy from "./assets/eco_buy.png";
import ecoShop from "./assets/eco_shop.png";
import ecoLeave from "./assets/eco_leave.png";
import ecoValues from "./assets/eco_values.png";
import value1 from "./assets/value_1.png";
import value2 from "./assets/value_2.png";
import value3 from "./assets/value_3.png";
import value4 from "./assets/value_4.png";
import value5 from "./assets/value_5.png";
import value6 from "./assets/value_6.png";
import valueBackground from "./assets/value_background.png";
import emilyBlossom from "./assets/emily_blossom.png";
import iconControl from "./assets/icon_control.png";
import iconShare from "./assets/icon_share.png";
import iconBlockchain from "./assets/icon_blockchain.png";
import iconGrow from "./assets/icon_grow.png";
import socialLinkedIn from "./assets/social_linkedin.png";
import socialInstagram from "./assets/social_instagram.png";
import socialFacebook from "./assets/social_facebook.png";
import bgLeft from "./assets/bg_left.png";
import plusMember from "./assets/plus_member.png";
import phoneRetailers from "./assets/phone_retailers.png";
import assistantSavings from "./assets/assistant_savings.png";
import assistantHome from "./assets/assistant_home.png";
import assistantTravel from "./assets/assistant_travel.png";
import assistantFood from "./assets/assistant_food.png";
import assistantEntertainment from "./assets/assistant_entertainment.png";
import assistantWellbeing from "./assets/assistant_wellbeing.png";
import leaves from "./assets/leaves.png";
import UKEcopay from "./assets/uk_ecopay.png";
import USAEcopay from "./assets/usa_ecopay.png";
import USA from "./assets/usa.jpg";
import UK from "./assets/uk.jpg";
import girlWithDog from "./assets/girl_with_dog.png";
import savePerMonth from "./assets/save_per_month.png";
import save1 from "./assets/save_1.png";
import save2 from "./assets/save_2.png";
import save3 from "./assets/save_3.png";
import save4 from "./assets/save_4.png";
import save5 from "./assets/save_5.png";
import save6 from "./assets/save_6.png";
import save7 from "./assets/save_7.png";
import save8 from "./assets/save_8.png";
import save9 from "./assets/save_9.png";
import RentalLogo from "./assets/RentalClub/rentallogo.jpg";
import Check from "./assets/RentalClub/check.png";
import Home from "./assets/RentalClub/home.png";
import House from "./assets/RentalClub/house.png";
import Pig from "./assets/RentalClub/piggy-bank.png";
import featuresBackground from "./assets/features_background.png";
import mobile from "./assets/mobile.png";
import ilovecommunity from "./assets/ilovecommunity.png";
import ecoPay from "./assets/eco_pay.png";

import CommunityIcon1 from "./assets/communitysection/1.png";
import CommunityIcon2 from "./assets/communitysection/2.png";
import CommunityIcon3 from "./assets/communitysection/3.png";
import CommunityIcon4 from "./assets/communitysection/4.png";
import CommunityIcon5 from "./assets/communitysection/5.png";

import icon1 from "./assets/icon_1.png";
import icon2 from "./assets/icon_2.png";
import icon3 from "./assets/icon_3.png";
import icon4 from "./assets/icon_4.png";
import ecoPayPhone from "./assets/ecopay_phone.png";
import iconCheck from "./assets/icon_check.png";
import backgroundLeft from "./assets/background_left.png";
import coin from "./assets/coin.png";
import crypto1 from "./assets/crypto_1.png";
import crypto2 from "./assets/crypto_2.png";
import token1 from "./assets/token_1.png";
import token2 from "./assets/token_2.png";
import token3 from "./assets/token_3.png";
import token4 from "./assets/token_4.png";
import itemIcon3 from "./assets/2_icon_3.png";
import ecoIDPhone from "./assets/eco_id_phone.png";
import ecoID from "./assets/eco_id.png";
import ecoChat from "./assets/eco_chat.png";
import member1 from "./assets/member_1.png";
import member2 from "./assets/member_2.png";
import member3 from "./assets/member_3.png";
import member4 from "./assets/member_4.png";
import member5 from "./assets/member_5.png";
import member6 from "./assets/member_6.png";
import member7 from "./assets/member_7.png";
import member8 from "./assets/member_8.png";
import member9 from "./assets/member_9.png";
import member10 from "./assets/member_10.png";
import member11 from "./assets/member_11.png";
import member12 from "./assets/member_12.png";
import member13 from "./assets/member_13.png";
import member14 from "./assets/member_14.png";
import member15 from "./assets/member_15.png";

import iconStore1 from "./assets/icon_store_1.png";
import iconStore5 from "./assets/coin.png";

import ecoStore from "./assets/eco_store.png";

import iconStore2 from "./assets/icon_store_2.png";
import iconStore3 from "./assets/icon_store_3.png";
import iconStore4 from "./assets/icon_store_4.png";

import bgEcoTokensLeft from "./assets/bg_eco_tokens_left.png";
import itemIcon1 from "./assets/2_icon_1.png";
import itemIcon2 from "./assets/2_icon_2.png";

import Introduction from "./assets/gifs/home_right.png";

import Introduction1 from "./assets/gifs/intro_1.png";

function App() {
  const [selectedCountry, setSelectedCountry] = useState("UK");
  const [retailers, setRetailers] = useState([]);
  const [deactivated, setDeactivated] = useState({});
  const [isMobileView, setMobileView] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  useEffect(() => {
    if (postcode.trim().length >= 6) {
      fetch(
        `https://api.getaddress.io/find/${postcode}?api-key=hA_HwnlAZ06Gro3XgGuUOQ32740`
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.addresses && data.addresses.length) {
            const address = data.addresses[0];
            setCity(address.split(",")[5]);
            console.log(city);
          }
        });
    }
  }, [postcode]);
  useEffect(() => {
    Firebase.getAllRetailers((res) => {
      const keys = Object.keys(res);
      const items = keys.map((key) => ({
        ...res[key],
        id: key,
      }));
      setRetailers(items);
    });

    Firebase.getAllDeactiveRetailers((res) => {
      setDeactivated(res);
    });

    checkDeviceStatus();
    window.addEventListener("resize", checkDeviceStatus);
  }, []);

  const checkDeviceStatus = () => {
    setMobileView(window.innerWidth < 550);
  };

  const checkTerritory = (retailer, territory) => {
    if (territory === "UK") {
      return !retailer.territory || retailer.territory === "UK";
    } else {
      return retailer.territory === "USA";
    }
  };
  const filteredRetailers = retailers
    .filter(
      (retailer) =>
        checkTerritory(retailer, selectedCountry) &&
        !deactivated[retailer.id] &&
        retailer.top10 &&
        retailer.top10 !== "none"
    )
    .sort((a, b) => a.top10 - b.top10)
    .slice(0, 20);

  const onPricingPlan = () => {
    window.open("https://brands.ecosystem.co.uk/signup", "_blank");
  };
  const onPostCodeChange = (event) => {
    setPostcode(event.target.value);
  };

  return (
    <div className="App">
      <div className="App-Content">
        <div className="Header pt-3 pb-3 pl-4 pr-4" style={{ 
          justifyContent: isMobileView && 'center' || "space-between"
        }}>
          <div>
            <img className="logo" src={RentalLogo}></img>
          </div>
          {!isMobileView && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <button onClick={()=>window.location.href="https://rentalclub.io"}>Login</button>
            <button onClick={()=>window.location.href="https://rentalclub.io"} className="ml-3" style={{ background: "rgb(181,254,181)" }}>
              Join
            </button>
          </div>}
        </div>
        <div className={"content-div " + (isMobileView?"p-2":"p-4")}>
          <p className="Text-2  ">
            Welcome to <strong>RentalClub</strong>. {isMobileView&&<br/>}We make renting more
            rewarding.
          </p>
          {isMobileView && <div style={{display: 'flex', marginBottom: "20px", justifyContent: 'center', alignItems: 'center'}}>
            <button onClick={()=>window.location.href="https://rentalclub.io"}>Login</button>
            <button className="ml-3" onClick={()=>window.location.href="https://rentalclub.io"} style={{ background: "rgb(181,254,181)" }}>
              Join
            </button>
          </div>}
          <div>
            <Container>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <div className="rb-card p-4 mt-5">
                    <div className="rb-card-header"><img src={Home} style={{width: "70%"}}></img></div>
                    <p className="Text-1 mt-4 font-weight-bold">Step 1</p>
                    <p className="Text-3 mt-4 font-weight-bold">
                      Add properties and housemates
                    </p>
                    <p className="Text-4 mt-3">
                      Create your wishlist of properties and those you want to
                      live with.
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={4} lg={3}>
                  <div className="rb-card p-4 mt-5">
                    <div className="rb-card-header"><img src={Check} style={{width: "70%"}}></img></div>
                    <p className="Text-1 mt-4 font-weight-bold">Step 2</p>
                    <p className="Text-3 mt-4 font-weight-bold">
                      Reference yourselves.
                    </p>
                    <p className="Text-4 mt-3">
                      Say bye to boring forms.
                      <br />
                      Chat with your reference {isMobileView&&<br/>}robot to verify yourself.
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={4} lg={3}>
                  <div className="rb-card p-4 mt-5">
                    <div className="rb-card-header"><img src={Pig} style={{width: "70%"}}></img></div>
                    <p className="Text-1 mt-4 font-weight-bold">Step 3</p>
                    <p className="Text-3 mt-4 font-weight-bold">
                      Pass to rent deposit {isMobileView&&<br/>}and stress free.
                    </p>
                    <p className="Text-4 mt-3">
                      Pass your reference and rental club will rent the property
                      your behalf covering risk for you.
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={4} lg={3}>
                  <div className="rb-card p-4 mt-5">
                    <div className="rb-card-header"><img src={House} style={{width: "70%"}}></img></div>
                    <p className="Text-1 mt-4 font-weight-bold">Step 4</p>
                    <p className="Text-3 mt-4 font-weight-bold">
                      Live in a fully serviced home.
                    </p>
                    <p className="Text-4 mt-3">
                      Service your property with cleaning, wifi, power and
                      concierge.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="Div-Bottom">
          <Container>
            <Row>
              <Col md="auto">
                <a href="#">
                  <img className="Img-Logo" src={logo} />
                </a>
              </Col>
              <Col></Col>
              <Col md="auto">
                <p className="Text-5 text-white">
                  All rights reserved Ecosystem Labs Ltd
                  <br />
                  UK - 22 Notting Hill Gate, London, W11 3JE
                  <br />
                  USA - 1601 Dove Street, Newport Beach, 9266
                </p>
              </Col>
              <Col></Col>
              <Col md="auto">
                <a href="#">
                  <img className="" src={socialLinkedIn} />
                </a>
                <a href="#">
                  <img className="" src={socialInstagram} />
                </a>
                <a href="#">
                  <img className="" src={socialFacebook} />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;
